import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { YellowButton } from '../../components/Global/Button'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { InlineLink } from '../../components/Global/Link'
import SEO from '../../components/Global/SEO'
import { AntrimCoastAndGlens, CarnloughHarbour, CushendunCaves, CarrickARedeRopeBridge, GiantsCauseway, DunluceCastle, Larrybane, TheDarkHedges } from '../../components/Tours/Locations'
import { ContentWrapper, InfoWithImageRight, InfoWithImageLeft, SVGPattern } from '../../components/Global/Content'
import SectionTitle from '../../components/Global/SectionTitle'
import { OffsetGridWrapper } from '../../components/Global/Content/OffsetGrid'

const GameOfThronesTour = ({ location }) => {
  
  const { seoImg, thrones, carnlough, dunluce } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "dark-hedges.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      thrones: file(relativePath: { eq: "dark-hedges.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carnlough: file(relativePath: { eq: "carnlough-harbour.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140, cropFocus: WEST) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dunluce: file(relativePath: { eq: "dunluce-castle.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Game of Thrones Tour | The Dark Hedges, Dunluce Castle, Carnlough Harbour"
        description="Discover the world of Westeros in all it's natural beauty as we visit seven of the main film locations used in Northern Ireland for the hit HBO series."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={thrones.childImageSharp.fluid}
        imageAlt="Giants Causeway"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Private Tour
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Game of Thrones
          <br className="hidden md:inline" />
          <span className="text-yellow-300">Tour</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Discover the world of Westeros in all it's natural beauty as we visit seven of the main film locations used in Northern Ireland for the hit HBO series.
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>
      </LeftWithImage>

      <ContentWrapper>
        <SectionTitle
          title="The Ultimate Game of Thrones Tour"
          text={<>Tour in comfort as you visit film locations of Game of Thrones, including the iconic Dark Hedges, in one of Chauffeur Me's <InlineLink to="/our-fleet" title="Chauffeur Me Luxury Vehicle Fleet">luxury fleet</InlineLink> of vehicles.</>}
        />
        <InfoWithImageRight
          image={carnlough.childImageSharp.fluid}
          imageAlt="Overlooking Carnlough Harbour which featured in a scene with Arya Stark."
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Ireland's Natural Beauty
          </h4>
          <p className="mt-3 text-lg leading-7 font-semibold text-gray-500">
            The Game of Thrones Tour with Chauffeur Me gives you the chance to see the world of Westeros in all its natural beauty.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            We travel up the Antrim Coast Road, travelling through the Glens of Antrim, an area of outstanding natural beauty. As we travel through the stunning scenery of the glens we weave past and take in some well-known parts of Westeros, learning of the areas local history. 
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            As we continue onward we'll stop off at Carnlough Harbour, where, in Season 6, Arya Stark jumps into the freezing water to escape the Waif and crawls up the steps into the streets of Braavos. Then before hitting the North Coast, we drop by the Cushendun Caves where Melisandre's shadow baby was born.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            A break for a bite to eat will follow at The Fullerton Arms, home to one of the famous Game of Thrones Doors, before a photo stop at Carrick-a-Rede and Larrybane, the site of Renly Baratheon’s Camp.
          </p>
        </InfoWithImageRight>

        <SVGPattern />

        <InfoWithImageLeft
          image={dunluce.childImageSharp.fluid}
          imageAlt="View of Dunluce Castle which featured as Game of Thrones' House of Greyjoy"
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            See More with Chauffeur Me
          </h4>
          <p className="mt-3 text-lg font-semibold leading-7 text-gray-500">
            No tour of Ireland's North Coast is complete without stopping off at the Giant's Causeway.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            The stories of giants and fairies that inhabit this area in Irish mythology are every bit in keeping with the Game of Thrones theme despite no filming of the HBO series taking place here.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Another quick stop before our final location sees us visit the ancient ruins of Dunluce Castle – perched on the edge of the cliffs, this is another fantastic photo opportunity. In the HBO show, Dunluce Castle is transformed into House of Greyjoy, ruler of the Iron Islands.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Finally, we visit the most iconic Game of Thrones locations on our tour, the Dark Hedges, better known as Kings Road in the HBO series. You will get the opportunity for some great photos with our cloaks and swords at this location.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            So what are you waiting for? The Game of Thrones Tour is available to <InlineLink to="/book-now">book now</InlineLink>.
          </p>
        </InfoWithImageLeft>

      </ContentWrapper>

      <OffsetGridWrapper title="Game of Thrones Tour Locations">
        <AntrimCoastAndGlens />
        <CarnloughHarbour />
        <CushendunCaves />
        <CarrickARedeRopeBridge />
        <Larrybane />
        <GiantsCauseway />
        <DunluceCastle />
        <TheDarkHedges />
      </OffsetGridWrapper>

    </Layout>
  )
}

export default GameOfThronesTour